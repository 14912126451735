import React, { PureComponent } from "react";

import ColumnalTextDisplay from "components/ColumnalTextDisplay";

import styles from "./style.module.scss";

export default class NotYourOrdinaryBrokerSection extends PureComponent {
  scrollIsDirty = false;

  constructor(props) {
    super(props);
    this.state = {
      shouldAnimateImages: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleFrame();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.scrollIsDirty = true;
  };

  handleFrame = () => {
    if (this.scrollIsDirty) {
      const scrollBottom =
        (window.pageYOffset || window.scrollY) + window.innerHeight;
      const revealThreshold = 0.7; // how much of element should be in viewport before animating
      const elementPosition =
        this.staticContainer.offsetTop +
        this.staticContainer.offsetHeight * revealThreshold;

      if (scrollBottom > elementPosition) {
        this.setState({ shouldAnimateImages: true });
        window.removeEventListener("scroll", this.handleScroll);
      }
      this.scrollIsDirty = false;
    }

    window.requestAnimationFrame(this.handleFrame);
  };

  render() {
    const { shouldAnimateImages } = this.state;
    return (
      <section
        ref={(ref) => {
          this.staticContainer = ref;
        }}
        className={styles.notYourOrdinaryBrokerSection}
      >
        <div
          className={`inset-layout ${styles.notYourOrdinaryBrokerContainer}`}
        >
          <div
            className={`${styles.iPhoneImage} ${
              shouldAnimateImages ? styles.animated : ""
            }`}
          >
            <picture>
              <img
                alt="an iPhone with a screenshot of the SquareFoot site"
                loading="lazy"
                src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/homepage/iPhone.png"
                width={244}
                height={492}
              />
            </picture>
          </div>
          <ColumnalTextDisplay
            className={styles.columnTextContainer}
            title="Not your ordinary broker."
          >
            <p>
              Work with SquareFoot brokers who help you to get started with
              smart tools to handle every facet of your search, from negotiating
              a lease, to finding an interior designer.
            </p>
            <a href="/profile/">
              <button type="button" className="btn-primary">
                Get Started
              </button>
            </a>
          </ColumnalTextDisplay>
          <div
            className={`${styles.laptopImage} ${
              shouldAnimateImages ? styles.animated : ""
            }`}
          >
            <picture>
              <img
                alt="A laptop with the SquareFoot onboarding wizard on screen"
                loading="lazy"
                src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/homepage/laptop.png"
              />
            </picture>
          </div>
        </div>
      </section>
    );
  }
}
